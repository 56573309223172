import { getAPIUserSummary } from "@/api/api-users/useCurrentUser";
import { getEnvConfig } from "@/config/environments";
import { GetServerSidePropsContext, Redirect } from "next";
import axios from "axios";

export const notAuthenticatedRedirect: (to: string) => [boolean, Redirect?] = (to: string) => {
  const params = new URLSearchParams({ to });

  const destination = to ? `/signin?${params}` : "/signin";

  return [
    false,
    {
      destination,
      permanent: false,
    },
  ];
};

/**
 * Helper to redirect Nextjs style.
 * https://nextjs.org/docs/api-reference/next.config.js/redirects
 */
export async function isUserAuthenticated(ctx: GetServerSidePropsContext): Promise<[boolean, Redirect?]> {
  const jwt = ctx.req.cookies.Authorization;

  if (!jwt) {
    return notAuthenticatedRedirect(ctx.resolvedUrl);
  }

  const api = newAPIClient(jwt);

  try {
    const user = await getAPIUserSummary(api);
    if (!user) {
      return notAuthenticatedRedirect(ctx.resolvedUrl);
    }

    return [true];
  } catch (err: any) {
    console.warn(err?.response?.data?.error);

    return notAuthenticatedRedirect(ctx.resolvedUrl);
  }
}

export function newAPIClient(jwt: string) {
  return axios.create({
    baseURL: getEnvConfig().APIDomain,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
}
