import { CanopyOsI18NSchema } from "@/context/i18n/types/generatedTypes";
import { PasswordPolicy, charsets } from "password-sheriff"; // Cognito form validation

import { setSession } from "@/services/authService";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { newAPIClient } from "@/libs/isUserAuthenticated";
import { getAPIUserSummary } from "@/api/api-users/useCurrentUser";

import { track } from "@/libs/analytics";

const passwordPolicy = new PasswordPolicy({
  contains: {
    expressions: [charsets.upperCase, charsets.lowerCase, charsets.numbers, charsets.specialCharacters],
  },
});

// This function checks the length of the password and whether it meets the password policy.
// It returns true if the password is valid, or an error message if the password is invalid.
export const validatePassword =
  (messages?: Partial<CanopyOsI18NSchema["auth"]["password"]["validation"]>) =>
  (value: string): true | string => {
    if (!value) {
      return messages?.required ?? "required";
    }
    if (!value || value.length < 12) {
      return messages?.length ?? "too short";
    }
    if (!passwordPolicy.check(value)) {
      return messages?.weak ?? "weak";
    }
    return true;
  };

export const completeSignin = async () => {
  // Saves the access token from the current session in Cookies.

  const currentSession = await Auth.currentSession();
  const token = currentSession.getAccessToken().getJwtToken();
  setSession(token);

  // Check if user is in db
  const api = newAPIClient(token);
  await getAPIUserSummary(api);

  track("Logged In");
};
